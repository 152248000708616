// @flow
import ShowOnScroll from '../../assets/js/utils/ShowOnScroll';

const selectorRoot = '.o-bar-bottom';

/**
 *
 * @param {HTMLElement} root The iframe root element
 */
export function iniOBarBottom(root: HTMLElement): ?ShowOnScroll {
	if (!root) {
		return;
	}

	const scrollOnShow = new ShowOnScroll(root, {
		listenOn: window,
		toggleClass: 'js-show',
		triggerAfterDistance: 130,
	});

	// eslint-disable-next-line consistent-return
	return scrollOnShow;
}

export default function ini(root: HTMLElement | Document = document) {
	root.querySelectorAll(selectorRoot).forEach(element => {
		iniOBarBottom(element);
	});
}
