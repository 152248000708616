// @flow
import normalizeUrl from 'normalize-url';
import isURL from 'validator/lib/isURL';
import {
	disableBackgroundScroll,
	enableBackgroundScroll,
} from '../../assets/js/utils/toggleBackgroundScroll';

const selectorRoot = '.o-overlay-info';
const loadInOverlayClass = '.js-load-in-overlay';
const showClass = 'js-show';
const hideClass = 'js-hide';
const isLoadingIframeClass = 'js-iframe-is-loading';
const loadedIframeClass = 'js-iframe-loaded';

/**
 * Automatically parses the whole page for open links.
 * It identifies a element to open the overly when it has
 * a ".js-load-in-overlay" class. It reads the href
 * value and use it to load the page in a iframe within
 * the overlay.
 * Optionally you can use a data-attribute to achieve the same
 * with: data-load-in-overlay="http://example.com"
 * @param {HTMLElement} root The iframe root element
 */
export function iniOverlay(root: HTMLElement) {
	if (!root) {
		return;
	}

	// get close button
	const closeButton = root.querySelector('.a-button-close');

	// get iframe
	const iframe = root.querySelector('.o-overlay-info__iframe');

	if (!(iframe instanceof HTMLIFrameElement) || !closeButton) {
		return;
	}

	function onIframeLoaded() {
		iframe.classList.remove(isLoadingIframeClass);
		iframe.classList.add(loadedIframeClass);
		iframe.removeEventListener('load', onIframeLoaded);
	}

	/**
	 * Set source url of the iframe
	 * @param {String} src src url for the iframe
	 */
	function setIframeSrc(src: string) {
		if (!iframe) {
			return;
		}
		const normalizedSrc = normalizeUrl(src, { stripWWW: false });
		if (iframe.src !== normalizedSrc) {
			iframe.setAttribute('src', normalizedSrc);
			iframe.classList.add(isLoadingIframeClass);
			iframe.classList.remove(loadedIframeClass);
			iframe.addEventListener('load', onIframeLoaded);
		}
	}

	// checks if the overlay is open
	function isOpen() {
		return root.classList.contains(showClass);
	}

	/**
	 * Opens the overlay
	 */
	function open() {
		if (isOpen()) {
			return;
		}
		disableBackgroundScroll();
		root.classList.add(showClass);
		root.classList.remove(hideClass);
	}

	// closes the overlay
	function close() {
		root.classList.add(hideClass);
		enableBackgroundScroll();
		// keep time in sync with css animation!!
		window.setTimeout(() => {
			root.classList.remove(showClass);
			root.classList.remove(hideClass);
		}, 400);
	}

	// ini close button functionality
	closeButton.addEventListener('click', (event: MouseEvent) => {
		event.preventDefault();
		close();
	});

	// get all open buttons and links of the page
	const openButtons = document.querySelectorAll(loadInOverlayClass);

	const urlCheckOptions = {
		require_tld: false,
		require_protocol: false,
		require_host: false,
		allow_protocol_relative_urls: true,
	};

	// add click event listener to open buttons
	openButtons.forEach(openButton => {
		let url;
		if (openButton instanceof HTMLAnchorElement) {
			url = openButton.href;
		} else {
			url = openButton.dataset.loadInOverlay;
		}

		if (typeof url !== 'string' || !isURL(url, urlCheckOptions)) {
			return;
		}

		if (typeof url === 'string') {
			openButton.addEventListener('click', (event: MouseEvent) => {
				event.preventDefault();
				setIframeSrc(url);
				open();
			});

			// prefetch url of iframe when mouse hovers already
			openButton.addEventListener('mouseover', (event: MouseEvent) => {
				event.preventDefault();
				setIframeSrc(url);
			});
		}
	});
}

export default function ini(root: HTMLElement | Document = document) {
	root.querySelectorAll(selectorRoot).forEach(element => {
		iniOverlay(element);
	});
}
