// @flow
type ConfigType = {
	listenOn: window | HTMLElement,
	toggleClass: string,
	triggerAfterDistance: number,
};
type ConfigInputType = {
	listenOn?: window | HTMLElement,
	toggleClass?: string,
	triggerAfterDistance?: number,
};

const defaultConfig: ConfigType = {
	// the element from which we get the scroll event
	listenOn: window,
	// class we add when the element should be shown
	toggleClass: 'js-show',
	// distance the scroll has to have till
	// we trigger a change
	triggerAfterDistance: 130,
};

/**
 * A class which helps to show/hide a element on scrollY
 * of another element. It hides automatically when the user
 * scrolls down and when he scrolls up, it shows again.
 * @param {HTMLElement} element The element which should be toggled
 * @param {Object} customConfig Config
 */
class ShowOnScroll {
	// class we add when the element should be shown
	_toggleClass: string;

	// distance the scroll has to have till a change will be triggered
	_triggerDistance: number;

	// element which should be triggered
	_element: HTMLElement;

	// element which scrolls and is our reference to
	// measure the scroll distance
	_scrollElement: window | HTMLElement;

	// if the element is currently shown
	_isShown: boolean;

	// technically last position for tracking when to trigger
	_lastPosition: number;

	// flag
	_isDestroyed: boolean;

	constructor(element: HTMLElement, customConfig: ConfigInputType = {}) {
		if (!(element instanceof HTMLElement)) {
			throw new TypeError('First parameter has to be a HTMLElement.');
		}

		const config = {
			...defaultConfig,
			...customConfig,
		};

		this._element = element;
		this._toggleClass = config.toggleClass;
		this._triggerDistance = config.triggerAfterDistance;
		this._scrollElement = config.listenOn;
		this._isDestroyed = false;
		this.start();
	}

	_getScrollY(): number {
		return Number(
			this._scrollElement.scrollY ||
				this._scrollElement.pageYOffset ||
				this._scrollElement.scrollTop
		);
	}

	isShown() {
		this._throwWhenDestroyed();
		return this._element.classList.contains(this._toggleClass);
	}

	start() {
		this._throwWhenDestroyed();
		this._lastPosition = this._getScrollY();
		// this._isShown = this._element.classList.contains(this._toggleClass);
		this._scrollElement.addEventListener('scroll', this._toggleShowState);
	}

	stop() {
		this._throwWhenDestroyed();
		this._scrollElement.removeEventListener('scroll', this._toggleShowState);
	}

	destroy() {
		this._throwWhenDestroyed();
		this._isDestroyed = true;
		this._scrollElement.removeEventListener('scroll', this._toggleShowState);
		delete this._element;
		delete this._scrollElement;
	}

	isDestroyed(): boolean {
		return this._isDestroyed;
	}

	_throwWhenDestroyed() {
		if (this.isDestroyed()) {
			throw new Error(
				"Class instance is destroyed and can't be used anymore. Check your code when you called the destroy() method."
			);
		}
	}

	_toggleShowState = () => {
		const scrollY = this._getScrollY();

		if (
			scrollY - this._lastPosition <= -this._triggerDistance &&
			!this.isShown()
		) {
			this._element.classList.add(this._toggleClass);
			// this._isShown = true;
		} else if (
			scrollY - this._lastPosition >= this._triggerDistance &&
			this.isShown()
		) {
			this._element.classList.remove(this._toggleClass);
			// this._isShown = false;
		} else if (Math.abs(scrollY - this._lastPosition) > this._triggerDistance) {
			this._lastPosition = scrollY;
		}
	};
}

export default ShowOnScroll;
