// NOTE: IMPORTANT: It doesn't work for iOS devices!!
// iOS Safari browser ignores the overflow: hidden attribute
// and so background scrolling still applies. An alternative
// could be found here: https://medium.com/jsdownunder/locking-body-scroll-for-all-devices-22def9615177

// removes the scroll effect of the body Element by setting with and height
// to 100 % and all overflow to hidden.
export const disableBackgroundScroll = () => {
	/* eslint-disable no-param-reassign */
	document.body.style.height = '100vh';
	document.body.style.width = '100vw';
	document.body.style.overflow = 'hidden';
	/* eslint-enable no-param-reassign */
};

// resetting scroll effect to normal by removing the style objects from the body
export const enableBackgroundScroll = () => {
	/* eslint-disable no-param-reassign */
	document.body.style.height = '';
	document.body.style.width = '';
	document.body.style.overflow = '';
	/* eslint-enable no-param-reassign */
};

// checks for style object in body
export const isBackgroundScrollEnabled = () =>
	!!(
		document.body.style.height &&
		document.body.style.width &&
		document.body.style.overflow
	);

// this function is toggling the scroll effect of the body. It should be invoked
// when an overlay is active and you want to get rid of the background scroll effect.
export default function toggleBackgroundScroll() {
	// first of all get the body element
	// check if scroll effect is set or not
	if (isBackgroundScrollEnabled()) {
		// if scroll effect is set we want to remove it
		enableBackgroundScroll();
	} else {
		// if scroll effect is not set, we want to set it
		disableBackgroundScroll();
	}
}
