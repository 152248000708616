// @flow
import {
	disableBackgroundScroll,
	enableBackgroundScroll,
} from '../../assets/js/utils/toggleBackgroundScroll';

const selectorRoot = '.o-menu-main';
const openMenuClass = '.js-open-menu';

/**
 * Opens the menu. Looks in the whole page for
 * js-open-menu classes and attach event listener
 * to them which will open the menu
 * Optionally you can use a data-attribute to achieve the same
 * with: data-load-in-menu="http://example.com"
 * @param {HTMLElement} root The iframe root element
 */
export function iniMenu(root: HTMLElement) {
	if (!root) {
		return;
	}

	// get open and close buttons
	const closeButton = root.querySelector('.m-control-menu');
	const openButtons = document.querySelectorAll(openMenuClass);

	if (!closeButton) {
		return;
	}

	// checks if the menu is open
	function isOpen() {
		return root.classList.contains('js-show');
	}

	/**
	 * Opens the menu
	 */
	function open() {
		if (isOpen()) {
			return;
		}
		disableBackgroundScroll();
		root.classList.add('js-show');
		root.classList.remove('js-hide');
	}

	// closes the menu
	function close() {
		root.classList.add('js-hide');
		enableBackgroundScroll();
		// keep time in sync with css animation!!
		window.setTimeout(() => {
			root.classList.remove('js-show');
		}, 400);
	}

	// ini close button functionality
	closeButton.addEventListener('click', (event: MouseEvent) => {
		event.preventDefault();
		close();
	});

	// add click event listener to open buttons
	openButtons.forEach(openButton => {
		if (openButton !== closeButton) {
			openButton.addEventListener('click', (event: MouseEvent) => {
				event.preventDefault();
				open();
			});
		}
	});

	// attache close event to esc-keyboard event
	document.addEventListener('keyup', event => {
		if (event.key === 27) {
			close();
		}
	});
}

export default function ini(root: HTMLElement | Document = document) {
	root.querySelectorAll(selectorRoot).forEach(element => {
		iniMenu(element);
	});
}

// import $ from 'jquery';
// import toggleBackgroundScroll from './../../assets/js/utils/toggleBackgroundScroll';

// export default function iniOMenuMain() {
// 	const menu = $('.o-menu-main');
// 	const menuButtons = $('.o-bar-menu__button');
// 	const menuOpenButton = menuButtons.filter('.js-show-menu').first();
// 	const menuCloseButton = menuButtons.filter('.js-close-menu').first();

// 	menuOpenButton.on('click', function() {
// 		menu.addClass('show');
// 		toggleBackgroundScroll();
// 	});

// 	menuCloseButton.on('click', function() {
// 		menu.removeClass('show');
// 		toggleBackgroundScroll();
// 	});
// }
