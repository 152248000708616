import $ from 'jquery';

const speed = 800;
const container = $('.o-section-gallery');

function formatPageNumber(pageNumber) {
	return String(pageNumber).padStart(2, '0');
}

function setPageNumber(pageNumber) {
	container
		.find('.m-navigation-gallery__current')
		.html(formatPageNumber(pageNumber));
}

function setMaxPageNumber(maxPageNumber) {
	container
		.find('.m-navigation-gallery__total')
		.html(formatPageNumber(maxPageNumber));
}

function iniSlideshowCurrent() {
	const slideshowCurrent = container.find(
		'.o-slideshow-gallery__container.current'
	);

	// set page number on change
	slideshowCurrent.on('afterChange', (slick, currentSlide) => {
		setPageNumber(currentSlide.currentSlide + 1);
	});

	// set max slides number of the gallery
	slideshowCurrent.on('init', function(event, slick) {
		setMaxPageNumber(slick.slideCount);
	});

	slideshowCurrent.slick({
		dots: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		touchMove: true,
		mobileFirst: true,
		prevArrow: '.m-navigation-gallery__button-prev',
		nextArrow: '.m-navigation-gallery__button-next',
		initialSlide: 0,
		speed,
	});

	return slideshowCurrent;
}

function iniSlideshowPrev() {
	const slideshowPrev = container.find('.o-slideshow-gallery__container.prev');

	slideshowPrev.slick({
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		touchMove: true,
		initialSlide: -1,
		arrows: false,
		swipe: false,
		speed,
	});

	return slideshowPrev;
}

function iniSlideshowNext() {
	const slideshowNext = container.find('.o-slideshow-gallery__container.next');

	slideshowNext.slick({
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		touchMove: true,
		initialSlide: 1,
		arrows: false,
		swipe: false,
		speed,
	});

	return slideshowNext;
}

function calcDirection(currentSlide, nextSlide, maxSlides) {
	if (currentSlide === maxSlides - 1 && nextSlide === 0) {
		return 'next';
	}
	if (currentSlide === 0 && nextSlide === maxSlides - 1) {
		return 'prev';
	}
	if (currentSlide < nextSlide) {
		return 'next';
	}
	if (currentSlide > nextSlide) {
		return 'prev';
	}
	// default
	// return 'next';
}

export default function iniOSectionIntroduction() {
	const slideshowCurrent = iniSlideshowCurrent();
	const slideshowPrev = iniSlideshowPrev();
	const slideshowNext = iniSlideshowNext();

	function syncSlidesCallback(event, slick, currentSlide, nextSlide) {
		const hasChanged = currentSlide !== nextSlide;

		if (!hasChanged) {
			return;
		}

		const direction = calcDirection(currentSlide, nextSlide, slick.slideCount);

		if (direction === 'next') {
			slideshowPrev.slick('slickNext');
			slideshowNext.slick('slickNext');
		} else {
			slideshowPrev.slick('slickPrev');
			slideshowNext.slick('slickPrev');
		}
	}

	function swipeSlideshows(direction) {
		if (direction === 'next') {
			slideshowCurrent.slick('slickNext');
			slideshowPrev.slick('slickNext');
			slideshowNext.slick('slickNext');
		} else {
			slideshowCurrent.slick('slickPrev');
			slideshowPrev.slick('slickPrev');
			slideshowNext.slick('slickPrev');
		}
	}

	slideshowCurrent.on('beforeChange', syncSlidesCallback);

	slideshowPrev.on('click', () => {
		swipeSlideshows('prev');
	});

	slideshowNext.on('click', () => {
		swipeSlideshows('next');
	});
}
