import $ from 'jquery';
import toggleBackgroundScroll from './../../assets/js/utils/toggleBackgroundScroll';

export default function iniOOverlayNewsletter() {
	// const desktopBreakpoint = 880;
	const newsletterOverlay = $('.o-overlay-newsletter');
	const newsletterSection = $('.o-section-newsletter');
	const buttonOpenNewsletterOverlay = newsletterSection.find(
		'.show-newsletter-overlay-js'
	);
	const newsletterCloseButton = newsletterOverlay.find('.js-close');
	const showOverlay = function(event) {
		event.preventDefault();
		newsletterOverlay.addClass('show');
		toggleBackgroundScroll();
	};

	const hideOverlay = function() {
		event.preventDefault();
		toggleBackgroundScroll();
		newsletterOverlay.removeClass('show');
	};

	buttonOpenNewsletterOverlay.on('click', showOverlay);
	newsletterCloseButton.on('click', hideOverlay);
}

//
// $(document).keyup(function(e) {
//      if (e.keyCode == 27) { // escape key maps to keycode `27`
//         // <DO YOUR WORK HERE>
//     }
// });
