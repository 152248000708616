// @flow
import ShowOnScroll from '../../assets/js/utils/ShowOnScroll';

const selectorRoot = '.js-o-bar-menu';
const stickyAfterHeight = 400;

/**
 *
 * @param {HTMLElement} root The iframe root element
 */
export function iniOverlay(root: HTMLElement) {
	if (!root) {
		return;
	}

	// FIX: to prevent initially firing the css animations
	// after page load
	root.classList.remove('js-hide-initial-animation-on-page-load');

	const scrollOnShow = new ShowOnScroll(root, {
		listenOn: window,
		toggleClass: 'js-sticky',
		triggerAfterDistance: 120,
	});

	let isSticky = false;

	function stickyNavigation() {
		if (window.scrollY >= stickyAfterHeight && !isSticky) {
			scrollOnShow.start();
			root.classList.add('js-sticky');
			isSticky = true;
		} else if (window.scrollY < stickyAfterHeight && isSticky) {
			scrollOnShow.stop();
			root.classList.remove('js-sticky');
			isSticky = false;
		}
	}

	window.addEventListener('scroll', stickyNavigation);
}

export default function ini(root: HTMLElement | Document = document) {
	root.querySelectorAll(selectorRoot).forEach(element => {
		iniOverlay(element);
	});
}
