/*
    Import stuff which should be placed at
    the end of the <body> section
 */
// import $ from 'jquery';
import 'slick-carousel';

import './../scss/global.scss';

import iniOMenuMain from './../../_menu/js/OMenuMain';
import iniLinkCopy from './../../_links/scripts/linkCopy';
import iniOSectionGallery from '../../_sections/scripts/OSectionGallery';
import iniOOverlayNewsletter from './../../_overlays/js/OOverlayNewsletter';
import iniOOverlayGallery from './../../_overlays/js/OOverlayGallery';
import iniOOverlayVideo from './../../_overlays/js/OOverlayVideo';
import iniOOverlayInfo from '../../_overlays/js/OOverlayInfo';
import iniOBarMenu from '../../_bars/js/OBarMenu';
import iniOBarBottom from '../../_bars/js/OBarBottom';

window.addEventListener('DOMContentLoaded', () => {
	iniLinkCopy();
	iniOMenuMain();
	iniOOverlayNewsletter();
	iniOOverlayGallery();
	iniOOverlayVideo();
	iniOSectionGallery();
	iniOOverlayInfo();
	iniOBarMenu();
	iniOBarBottom();

	console.log('%cSite ready', 'background: #8be09f; color: #39485e');
});
// $(document).ready(() => {

// });
