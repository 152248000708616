import $ from 'jquery';
import toggleBackgroundScroll from './../../assets/js/utils/toggleBackgroundScroll';

const getInitialSlide = function() {
	let currentSlide = $('.current .o-slide-image.slick-current').data(
		'slick-index'
	);
	if (!currentSlide) {
		currentSlide = $('.o-section-main .o-slide-image.slick-current').data(
			'slick-index'
		);
	}

	return currentSlide;
};

export default function iniOOverlayGallery() {
	const desktopBreakpoint = 880;
	const galleryOverlay = $('.o-overlay-gallery');
	const gallerySlideshow = galleryOverlay.find(
		'.o-slideshow-gallery__container'
	);
	const buttonShowOverlay = $('.js-show-gallery-overlay');
	const buttonCloseOverlay = galleryOverlay.find('.js-close');

	const initSlideshow = function(event) {
		const initialSlide = getInitialSlide();

		gallerySlideshow.slick({
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			mobileFirst: true,
			prevArrow: '.m-navigation-gallery__button-prev',
			nextArrow: '.m-navigation-gallery__button-next',
			speed: 800,
			initialSlide,
		});
	};

	const showOverlay = function(event) {
		event.preventDefault();
		const windowWidth = window.innerWidth;
		if (windowWidth >= desktopBreakpoint) {
			return true;
		}
		initSlideshow();
		galleryOverlay.addClass('show');
		toggleBackgroundScroll();
	};
	const hideOverlay = function(event) {
		event.preventDefault();
		gallerySlideshow.slick('unslick');
		galleryOverlay.removeClass('show');
		toggleBackgroundScroll();
	};

	buttonShowOverlay.on('click', showOverlay);
	buttonCloseOverlay.on('click', hideOverlay);
}
