import $ from 'jquery';
import toggleBackgroundScroll from './../../assets/js/utils/toggleBackgroundScroll';

export default function iniOOverlayGallery() {
	const videoOverlay = $('.o-overlay-video');
	const videoContainer = videoOverlay.find('.o-overlay-video__container');
	const buttonShowOverlay = $('.js-show-video-overlay');
	const buttonCloseOverlay = videoOverlay.find('.js-close');
	const video = $(
		"<iframe class='o-overlay-video__video' width='1600' frameborder='0' allowfullscreen src='https://www.youtube.com/embed/XGSy3_Czz8k?controls=1&autoplay=1'></iframe>"
	);

	const showOverlay = function(event) {
		event.preventDefault();
		videoContainer.prepend(video);
		videoOverlay.addClass('show');
		toggleBackgroundScroll();
	};
	const hideOverlay = function(event) {
		event.preventDefault();
		video.remove();
		videoOverlay.removeClass('show');
		toggleBackgroundScroll();
	};

	buttonShowOverlay.on('click', showOverlay);
	buttonCloseOverlay.on('click', hideOverlay);
}
