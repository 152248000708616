import $ from 'jquery';

export default function iniLinkCopy() {
	const link = $('.js-button-copy');

	const copyToClipboard = function(node) {
		const clickedElement = $(node);
		const tempInput = $('<input>');
		$('body').append(tempInput);
		tempInput.val(clickedElement.attr('href')).select();
		document.execCommand('copy');
		tempInput.remove();
	};

	const displayResponse = function() {
		const responseBanner = $(
			"<div class='o-overlay o-overlay-response'><span class='o-overlay-response__message'>Link wurde kopiert</span></div>"
		);
		$('body').append(responseBanner);
		responseBanner.fadeTo(1700, 1).fadeTo(1000, 0);
		const removeResponseBanner = () => responseBanner.remove();
		window.setTimeout(removeResponseBanner, 2700);
	};
	link.on('click', event => {
		event.preventDefault();
		copyToClipboard(event.currentTarget);
		displayResponse();
	});
}
